"use strict";

require('./imports/bootstrap');

import { initTrixEditor } from "./imports/trix-editor";
import { initAutoComplete } from "./imports/auto-complete";

// Document is ready!
document.addEventListener('DOMContentLoaded', function() {

    //initTrixEditor();      // Trix Editor
    initAutoComplete();    // AutoComplete

});
